import React, { useMemo } from 'react';
import { PageHeader, Container, SEO, Strings, StringsWrapper } from '@fragments';
import styled from 'styled-components';
import { useStateScreenMobile } from '@helpers/hooks';
import { spaceMb, spaceDt, screenMin } from '@helpers/styles';
import { Answer, Question, Relation, Subheader, Bio } from './components';
import ContactForm from '@features/ContactForm';
import { ContactFormHeader } from '@features/ContactForm/ContactForm.styled';
import { InterviewProps, CHUNK_NAME } from './Interview.types';

const StyledPageHeader = styled(PageHeader)`
  margin-bottom: ${spaceMb(12.5)};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(12.5)};
  }
`;

const StyledContainer = styled(Container)`
  margin-bottom: ${spaceMb(12.5)};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(12.5)};
  }
`;

export const Interview: React.FC<{ pageContext: { data: InterviewProps } }> = ({
  pageContext: {
    data: { header, subheader, seo, relation, contentList, coverPhotoData, bios, bioImageDataSets },
  },
}) => {
  const isMobileScreen = useStateScreenMobile();
  const parsedContent = useMemo(() => {
    return contentList.map(({ type, content, contentObj }, index) => {
      switch (type) {
        case CHUNK_NAME.QUESTION:
          return <Question content={content} key={index} />;
        case CHUNK_NAME.ANSWER:
          return <Answer content={content} key={index} />;
        default:
          return content ?? contentObj;
      }
    });
  }, [contentList]);

  const biosContent =
    bios?.length > 0 &&
    bios.map((bio, index) => (
      <Bio
        key={index}
        name={bio.name}
        content={bio.content}
        imageData={bioImageDataSets?.filter((dataSet) => dataSet.name === bio.imageName)[0].data}
      />
    ));

  return (
    <StringsWrapper>
      {isMobileScreen ? (
        <>
          <Strings name="fund-climate--mobile-1" height={155} top={-60} mobile />
          <Strings name="fund-climate--mobile-2" height={352} top={200} mobile />
          <Strings name="fund-sustainability--mobile" height={419} top={80} mobile />
        </>
      ) : (
        <>
          <Strings name="fund-climate" height={613} top={700} />
          <Strings name="fund-sustainability" height={1303} top={1300} />
        </>
      )}

      {!!(seo?.title || seo?.description) && <SEO title={`${seo.title} | Ragnarson`} description={seo.description} />}

      <StyledPageHeader
        name="Careers"
        headline={header.headline}
        content={header.content}
        linkArray={header.linkArray}
        ctaObj={header?.cta}
      />

      <StyledContainer>
        {!!subheader && <Subheader imageData={coverPhotoData} {...subheader} />}

        {parsedContent}
      </StyledContainer>

      <StyledContainer>
        {biosContent}

        {!!relation && <Relation {...relation} />}
      </StyledContainer>

      <ContactForm headline={<ContactFormHeader>Contact us</ContactFormHeader>} />
    </StringsWrapper>
  );
};
