import React from 'react';
import { SplitText } from '../index';
import styled from 'styled-components';
import { IconQuote } from '@icons';
import { spaceMb, spaceDt, screenMin, getTypography } from '@helpers/styles';

interface Answer {
  content: string;
}

const Container = styled.div`
  display: flex;
  margin-top: ${spaceMb(4)};

  ${screenMin('lg')} {
    margin-top: ${spaceDt(4)};
  }
`;

const Content = styled.div`
  flex-grow: 1;
  ${getTypography('body-1')}
`;

const StyledIconQuote = styled(IconQuote)`
  flex-shrink: 0;
  margin-right: ${spaceMb(2)};

  ${screenMin('lg')} {
    margin-right: ${spaceDt(4)};
  }
`;

export const Answer: React.FC<Answer> = ({ content }) => (
  <Container>
    <StyledIconQuote />
    <Content>
      <SplitText content={content} />
    </Content>
  </Container>
);
