import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Link } from '@fragments';
import { spaceMb, spaceDt, screenMin, getTypography } from '@helpers/styles';

interface RelationProps {
  content: string;
  cta: Link;
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin-top: ${spaceMb(12.5)};
  margin-bottom: ${spaceMb(12.5)};

  ${screenMin('lg')} {
    margin-top: ${spaceDt(12.5)};
    margin-bottom: ${spaceDt(12.5)};
  }
`;

const Disclaimer = styled.h3`
  width: 100%;
  text-align: center;
  ${getTypography('heading-2')}
`;

const StyledButton = styled(Link)`
  margin-top: ${spaceMb(5)};

  ${screenMin('lg')} {
    margin-top: ${spaceDt(5)};
  }
`;

export const Relation: React.FC<RelationProps> = ({ content, cta }) => {
  const parsedDisclaimer = useMemo(
    () =>
      content.split('\n').map((chunk, index) =>
        index > 0 ? (
          <span key={index}>
            <br />
            {chunk}
          </span>
        ) : (
          <span key={index}>{chunk}</span>
        )
      ),
    [content]
  );

  return (
    <Container>
      <Disclaimer>{parsedDisclaimer}</Disclaimer>
      <StyledButton cta="true" to={cta.to} title={cta.title}>
        {cta.label}
      </StyledButton>
    </Container>
  );
};
