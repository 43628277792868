import React from 'react';
import styled from 'styled-components';
import { toVW } from '@helpers/methods';
import { screenMin, spaceMb, spaceDt, color, getFontSet } from '@helpers/styles';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

interface BioProps {
  imageData: IGatsbyImageData | undefined;
  name: string;
  content: string;
}

const Container = styled.div`
  background-color: ${color.accent[1]};
  margin-top: ${spaceMb(4)};
  display: flex;
  flex-direction: column;
  padding: ${spaceMb(5)};

  ${screenMin('lg')} {
    padding: ${spaceDt(5)};
    margin-top: ${spaceDt(4)};
    flex-direction: row;
  }
`;

const ImageContainer = styled.div`
  flex-shrink: 0;
  width: ${toVW(160, 'mobile')};
  height: ${toVW(160, 'mobile')};
  margin-right: ${spaceMb(6)};
  margin-bottom: ${spaceMb(2)};

  ${screenMin('lg')} {
    width: ${toVW(160, 'desktop')};
    height: ${toVW(160, 'desktop')};
    margin-right: ${spaceDt(6)};
    margin-bottom: 0;
  }
`;

const BioContainer = styled.div`
  flex-grow: 1;
`;

const BioName = styled.h3`
  ${getFontSet('body-1', 'desktop', 'mobile')};
  margin-bottom: ${spaceMb(1.5)};

  ${screenMin('lg')} {
    ${getFontSet('body-1', 'desktop')};
    margin-bottom: ${spaceDt(1.5)};
  }
`;

export const Bio: React.FC<BioProps> = ({ imageData, name, content }) => {
  const image = !!imageData && getImage(imageData);

  return (
    <Container>
      {!!image && (
        <ImageContainer>
          <GatsbyImage image={image} alt={name} />
        </ImageContainer>
      )}

      <BioContainer>
        <BioName>{name}</BioName>
        {content}
      </BioContainer>
    </Container>
  );
};
