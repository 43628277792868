import { IGatsbyImageData } from 'gatsby-plugin-image';

export enum CHUNK_NAME {
  QUESTION = 'question',
  ANSWER = 'answer',
}

export interface InterviewChunk {
  type: CHUNK_NAME;
  content: 'string';
  contentObj: {
    disclaimer: string;
    image?: 'string' | undefined;
    cta?: Link | undefined;
  };
}

export interface InterviewBio {
  imageName: string;
  name: string;
  content: string;
}

export interface InterviewProps {
  header: PageHeader;
  bios: [InterviewBio];
  bioImageDataSets:
    | [
        {
          name: string;
          data: IGatsbyImageData;
        }
      ]
    | null;
  seo?: {
    title: string;
    description: string;
  };
  subheader: {
    content: string;
    imageName: string;
  };
  relation: {
    content: string;
    cta: {
      label: string;
      title: string;
      to: string;
    };
  };
  contentList: [InterviewChunk];
  coverPhotoData: IGatsbyImageData | null;
}
