import React from 'react';
import styled from 'styled-components';
import { getTypography, screenMin, spaceMb, spaceDt } from '@helpers/styles';
import { toVW } from '@helpers/methods';

interface Question {
  content: string;
}

const Container = styled.div`
  ${getTypography('heading-2')};
  margin-top: ${spaceMb(12.5)};

  ${screenMin('lg')} {
    padding-right: ${toVW(110, 'desktop')};
    margin-top: ${spaceDt(12.5)};
  }
`;

export const Question: React.FC<Question> = ({ content }) => <Container>{content}</Container>;
