import React, { useMemo } from 'react';
import { LinkExternal } from '@fragments';
import { spaceMb, spaceDt, screenMin, color } from '@helpers/styles';
import styled from 'styled-components';
import uniqid from 'uniqid';

const P = styled.p`
  margin-bottom: 0;
  &:not(:first-child) {
    margin-top: ${spaceMb(2)};
  }

  ${screenMin('lg')} {
    &:not(:first-child) {
      margin-top: ${spaceDt(4)};
    }
  }
`;

const StyledLinkExternal = styled(LinkExternal)`
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
  color: ${color.text.outline};
`;

interface SplitTextProps {
  content: string;
}

export const SplitText: React.FC<SplitTextProps> = ({ content }) => {
  const regexUrlMd = /\[([^\[]+)\)/gm;
  const regexUrl = /\[([^\[]+)\](\(.*\))/gm;
  const paragraphs = content.split('\n');
  const parsedContent = useMemo(
    () =>
      paragraphs.map((paragraph) => {
        if (!paragraph.search(regexUrl)) return <P key={uniqid()}>{paragraph}</P>;

        return (
          <P key={uniqid()}>
            {paragraph
              .split(regexUrlMd)
              .map((bit) => {
                const shards = bit.split('](');

                if (shards.length === 1) return <span key={uniqid()}>{shards[0]}</span>;

                /* eslint-disable react/jsx-key */
                return (
                  <StyledLinkExternal key={uniqid()} to={shards[1]} title={`Przeczytaj więcej tutaj:\n ${shards[1]}`}>
                    {shards[0]}
                  </StyledLinkExternal>
                );
              })
              .flat()}
          </P>
        );
      }),
    [content]
  );

  return <>{parsedContent}</>;
};
