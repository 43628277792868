import React from 'react';
import styled from 'styled-components';
import { toVW } from '@helpers/methods';
import { screenMin, spaceMb, spaceDt, getTypography, screenMax } from '@helpers/styles';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

interface SubheaderProps {
  content: string;
  imageName: string;
  imageData: IGatsbyImageData | null;
}

const Container = styled.div`
  display: flex;
  ${screenMax('lg')} {
    flex-direction: column;
  }
`;

const CoverPhotoWrapper = styled.div`
  flex-shrink: 0;

  ${screenMax('lg')} {
    width: 100%;
    max-width: initial;
    margin-bottom: ${spaceMb(3)};
  }

  ${screenMin('lg')} {
    max-width: ${toVW(500, 'desktop')};
    margin-right: ${spaceDt(6.5)};
  }
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  ${getTypography('body-1')}
`;

export const Subheader: React.FC<SubheaderProps> = ({ content, imageName, imageData }) => {
  const image = !!imageData && getImage(imageData);

  return (
    <Container>
      {!!image && (
        <CoverPhotoWrapper>
          <GatsbyImage image={image} alt={imageName} />
        </CoverPhotoWrapper>
      )}
      <Content>{content}</Content>
    </Container>
  );
};
